import PrimaryButton from '@/app/ui-new/components/PrimaryButton/PrimaryButton'
import React, { useState, useEffect } from 'react'
import SecondaryButton from '@/app/ui-new/components/SecondaryButton/SecondaryButton'
import i18n from './MarketPlaceHeader.i18n'
import { Box, Grid, Hidden } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory, useLocation } from 'react-router-dom'
import { useI18n } from '@/app/ui/components/hooks/I18n'
import { useDispatch, useSelector } from 'react-redux'
import { getUserOrganization as reduxGetUserOrganization } from '@/app/ui/redux/selectors'
import { getOrder as reduxGetOrder } from '@/app/ui-new/redux/selectors/MarketPlaceSelectors'
import { getMarketPlaceStepPath } from '@/app/ui-new/pages/marketplace/helpers/marketplaceHelpers'
import { MarketPlaceStep } from '@/app/ui-new/definitions/MarketPlace'
import {
    Header as StyledHeader,
    HeaderSteps,
    HeaderTitle,
    NewOrderButton,
    ModuleName,
    OrganizationName,
    ResetButton,
} from './MarketPlaceHeader.styled'

import { Dialog, DialogTitle, DialogText, DialogButtonContainer } from '@/app/ui-new/components/Dialog/Dialog'

import {
    getCurrentStep as reduxGetCurrentStep,
    getLastCompletedStep as reduxGetLastCompletedStep,
} from '@/app/ui-new/redux/selectors/MarketPlaceSelectors'

import {
    setMarketPlaceStep as reduxSetMarketPlaceStep,
    setFillMarketPlaceTrigger as reduxSetFillMarketPlaceTrigger,
    setPromptUnsavedChangesDialog as reduxSetPromptUnsavedChangesDialog,
} from '@/app/ui-new/redux/actions/MarketPlaceActions'
import { useMarketplaceNavigator } from '../../pages/marketplace/hooks/useMarketplaceNavigator'

import { deleteReduxOrderSnapshot} from '@/app/ui-new/pages/marketplace/helpers/marketplaceHelpers'

import { faFileLines, faLocationDot } from '@fortawesome/pro-regular-svg-icons-lazr-alias'

export const MarketPlaceHeader: React.FunctionComponent = () => {
    const { t } = useI18n(i18n)
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()

    const currentStep =  Number(localStorage.getItem('newMarketPlaceStep')??0)?? useSelector(reduxGetCurrentStep)
    const lastCompletedStep =  Number(localStorage.getItem('newMarketPlaceLastCompletedStep')??0) ?? useSelector(reduxGetLastCompletedStep)


    const userOrganization = useSelector(reduxGetUserOrganization)
    const { resetMarketplace } = useMarketplaceNavigator()

    const order = useSelector(reduxGetOrder)
    const isCustomQuote = order.rfq?.customQuoteRequested ?? false

    const [isResetOrderDialogOpen, setIsResetOrderDialogOpen] = useState(false)

    const isActive = (step: MarketPlaceStep) => step === currentStep
    const isCompleted = (step: MarketPlaceStep) => step <= lastCompletedStep

    const handleChangeStep = (step: MarketPlaceStep) => {
        if (!isActive(step) && isCompleted(step - 1)) {
            dispatch(reduxSetMarketPlaceStep(step))

            history.push(getMarketPlaceStepPath(step, location.pathname) + (location.search as string) + (location.hash as string))
        }
    }

    const handleResetOrder = () => {
        {order?.id && deleteReduxOrderSnapshot(order.id)}
        dispatch(reduxSetFillMarketPlaceTrigger(null))
        resetMarketplace()
    }

    const getClassName = (...steps: number[]): string => {
        if (steps.includes(currentStep)) {
            return 'active'
        }

        const maxStep = Math.max(...steps)

        if (lastCompletedStep >= maxStep) {
            return 'completed'
        }

        if (maxStep > lastCompletedStep + 1) {
            return 'disabled'
        }

        return ''
    }

    return (
        <StyledHeader>
            <Grid container>
                <Grid item  md={4} lg={4}>
                    <HeaderTitle>
                        <ModuleName>{t('Marketplace')}</ModuleName>
                        <OrganizationName>{userOrganization?.name || ''}</OrganizationName>
                    </HeaderTitle>
                </Grid>
                {/* <Grid container item sm={12} md={12} lg={8} justifyContent='flex-end'>
                    {currentStep < 7 ? (
                            <Box display='flex' flexDirection='row' alignItems='center' >
                                    <HeaderSteps>
                                        <span
                                            className={`${getClassName(0)} ${getClassName(1)}`}
                                            onClick={() => {
                                                handleChangeStep(1)
                                            }}
                                        >
                                            {lastCompletedStep >= 1 ? (
                                                <FontAwesomeIcon icon={['far', 'check-circle']} />
                                            ) : (
                                                <FontAwesomeIcon icon={['far', 'truck']} />
                                            )}
                                            {currentStep === 1 && <Hidden mdUp>{t('Cargo')}</Hidden>}
                                            <Hidden smDown>{t('Cargo')}</Hidden>
                                        </span>
                                        <FontAwesomeIcon icon={['far', 'angle-right']} className='arrow' />
                                        <span
                                            className={getClassName(2)}
                                            onClick={() => {
                                                handleChangeStep(2)
                                            }}
                                        >
                                            {lastCompletedStep >= 2 ? (
                                                <FontAwesomeIcon icon={['far', 'check-circle']} />
                                            ) : (
                                                // @ts-ignore
                                                <FontAwesomeIcon icon={faLocationDot} />
                                            )}

                                            <Hidden mdUp>{currentStep === 2 && t('Transit')}</Hidden>
                                            <Hidden smDown>{t('Transit')}</Hidden>
                                        </span>
                                        <FontAwesomeIcon icon={['far', 'angle-right']} className='arrow' />
                                        <span
                                            className={getClassName(3)}
                                            onClick={() => {
                                                handleChangeStep(3)
                                            }}
                                        >
                                            {lastCompletedStep >= 3 ? (
                                                <FontAwesomeIcon icon={['far', 'check-circle']} />
                                            ) : (
                                                // @ts-ignore
                                                <FontAwesomeIcon icon={faFileLines} />
                                            )}
                                            <Hidden mdUp> {currentStep === 3 && t('Coverage')}</Hidden>{' '}
                                            <Hidden smDown>{t('Coverage')}</Hidden>
                                        </span>
                                
                                        <FontAwesomeIcon icon={['far', 'angle-right']} className='arrow' />
                                        <span
                                            className={isCustomQuote ? 'disabled' : getClassName(4)}
                                            onClick={() => {
                                                handleChangeStep(4)
                                            }}
                                        >
                                            {lastCompletedStep >= 4 ? (
                                                <FontAwesomeIcon icon={['far', 'check-circle']} />
                                            ) : (
                                                <FontAwesomeIcon icon={['far', 'store']} />
                                            )}
                                            <Hidden mdUp> {currentStep === 4 && t('Results')}</Hidden>{' '}
                                            <Hidden smDown>{t('Results')}</Hidden>
                                        </span>
                                        <FontAwesomeIcon icon={['far', 'angle-right']} className='arrow' />
                                        <span
                                            className={isCustomQuote ? 'disabled' : getClassName(5)}
                                            onClick={() => {
                                                handleChangeStep(5)
                                            }}
                                        >
                                            {lastCompletedStep >= 5 ? (
                                                <FontAwesomeIcon icon={['far', 'check-circle']} />
                                            ) : (
                                                <FontAwesomeIcon icon={['far', 'user-friends']} />
                                            )}
                                            <Hidden mdUp> {currentStep === 5 && t('Details')}</Hidden>{' '}
                                            <Hidden smDown>{t('Details')}</Hidden>
                                        </span>
                                        <FontAwesomeIcon icon={['far', 'angle-right']} className='arrow' />
                                        <span
                                            className={getClassName(6)}
                                            onClick={() => {
                                                handleChangeStep(6)
                                            }}
                                        >
                                            {lastCompletedStep >= 6 ? (
                                                <FontAwesomeIcon icon={['far', 'check-circle']} />
                                            ) : (
                                                <FontAwesomeIcon icon={['far', 'clipboard-list-check']} />
                                            )}
                                            <Hidden mdUp>{currentStep === 6 && t('Checkout')}</Hidden>{' '}
                                            <Hidden smDown>{t('Checkout')}</Hidden>
                                        </span>
                                    </HeaderSteps>
                               

                                <ResetButton
                                    size='small'
                                    color='secondary'
                                    endIcon={<FontAwesomeIcon icon={['far', 'undo-alt']} />}
                                    onClick={() => {
                                        setIsResetOrderDialogOpen(true)
                                        dispatch(reduxSetPromptUnsavedChangesDialog(false))
                                    }}
                                >
                                    <Hidden smDown> {t('Reset order')}</Hidden>
                                </ResetButton>
                            </Box>
                    ) : (
                        <NewOrderButton size='small' endIcon={<FontAwesomeIcon icon={['far', 'file-alt']} />} onClick={handleResetOrder}>
                            {t('Create new order')}
                        </NewOrderButton>
                    )}
                </Grid> */}

                <Dialog
                    open={isResetOrderDialogOpen}
                    onClose={() => {
                        setIsResetOrderDialogOpen(false)
                    }}
                    onBackdropClick={() => {
                        setIsResetOrderDialogOpen(false)
                    }}
                >
                    <DialogTitle>{t('Reset Order')}</DialogTitle>
                    <DialogText mt={4} mb={2}>
                        {t('Are you sure you want to reset the current order?')}
                    </DialogText>
                    <DialogButtonContainer>
                        <SecondaryButton
                            size='medium'
                            onClick={() => {
                                setIsResetOrderDialogOpen(false)
                            }}
                        >
                            {t('Cancel')}
                        </SecondaryButton>
                        <PrimaryButton
                            size='medium'
                            onClick={() => {
                                handleResetOrder()
                                setIsResetOrderDialogOpen(false)
                            }}
                        >
                            {t('Reset')}
                        </PrimaryButton>
                    </DialogButtonContainer>
                </Dialog>
            </Grid>
        </StyledHeader>
    )
}
