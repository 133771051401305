import { handleUnauthorizedException } from './index'
import { logger } from '../../logger'
import {
    ApiError,
    //ErrorResponse,
    UuidV4,
    OrderV2,
    OrderV2Service,
} from '@lazr/openapi-client'

import { ServerErrorMessage, generateServerErrorMessage } from '@/app/ui-new/lib/helpers'

export interface AgreementsPaging {
    page?: number
    resultPerPage?: number
}

export const OrderV2ApiService = Object.freeze({
    getById: async (id: UuidV4): Promise<OrderV2> => {
        let getByIdResponse: any
        try {
            getByIdResponse = await OrderV2Service.getOrderV2ById(id)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('OrderV2 getById', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse: ServerErrorMessage = JSON.parse(JSON.stringify(error, null, 4))
                throw errorResponse
            }
            throw generateServerErrorMessage('Unable to get orderv2 by id')
        }

        if (!getByIdResponse.data) {
            logger.debug('getById', JSON.stringify(getByIdResponse, null, 4))
            throw generateServerErrorMessage('Unable to get orderv2 by id (No Data)')
        }

        return getByIdResponse.data
    },

    edit: async(orderAttributes: Partial<OrderV2>): Promise<OrderV2> => {
        let editResponse

        try {
            editResponse = await OrderV2Service.editOrderV2(orderAttributes)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('OrderV2 edit', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse: ServerErrorMessage = JSON.parse(JSON.stringify(error, null, 4))
                throw errorResponse
            }
            throw generateServerErrorMessage('Unable to edit orderv2')
        }

        if (!editResponse.data) {
            logger.debug('Agreement', JSON.stringify(editResponse, null, 4))
            throw generateServerErrorMessage('Unable to edit orderv2 (No Data)')
        }

        return editResponse.data
    },

    delete: async (id: UuidV4): Promise<OrderV2> => {
        let deleteResponse: any
        try {
            deleteResponse = await OrderV2Service.deleteOrderV2({id: id})
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('OrderV2 deleteOrderV2', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse: ServerErrorMessage = JSON.parse(JSON.stringify(error, null, 4))
                throw errorResponse
            }
            throw generateServerErrorMessage('Unable to delete orderv2 by id')
        }

        if (!deleteResponse.data) {
            logger.debug('getById', JSON.stringify(deleteResponse, null, 4))
            throw generateServerErrorMessage('Unable to get orderv2 by id (No Data)')
        }

        return deleteResponse.data[0]
    },
})
