/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Partial_OrderV2_ } from '../models/Partial_OrderV2_';
import type { SuccessResponse_OrderV2_ } from '../models/SuccessResponse_OrderV2_';
import { request as __request } from '../core/request';

export class OrderV2Service {

    /**
     * Delete a order content.
     * @param requestBody The order data
     * @returns SuccessResponse_OrderV2_ OK
     * @throws ApiError
     */
    public static async deleteOrderV2(
        requestBody: {
            id: string,
        },
    ): Promise<SuccessResponse_OrderV2_> {
        const result = await __request({
            method: 'POST',
            path: `/delete-orderv2`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Edit a order content.
     * @param requestBody The order data
     * @returns SuccessResponse_OrderV2_ OK
     * @throws ApiError
     */
    public static async editOrderV2(
        requestBody: Partial_OrderV2_,
    ): Promise<SuccessResponse_OrderV2_> {
        const result = await __request({
            method: 'POST',
            path: `/edit-orderv2`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get an order by ID.
     * @param id The order ID
     * @returns SuccessResponse_OrderV2_ OK
     * @throws ApiError
     */
    public static async getOrderV2ById(
        id: string,
    ): Promise<SuccessResponse_OrderV2_> {
        const result = await __request({
            method: 'GET',
            path: `/orderv2-by-id`,
            query: {
                'id': id,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

}